<template>
  <div class="home">
    <div class="home-title"  @click="handleListData">
      <h3>门店列表</h3>
      <img src="~@/assets/img/home/titleIco.png"/>
    </div>
    <div class="home-pie">
      <!-- <div id="pie"></div> -->
      <div class="pie-itme">
        <div class="item-data">
          <p>{{obj.shopNum}}</p>
          <div class="item-test">总店数</div>

        </div>
      </div>
    </div>
    <!-- <div class="home-center" @click="handleListData">
      <img class="log" src="~@/assets/img/home/log.png" />
      <div>霖感门店的分店列表</div>
      <img class="boult" src="~@/assets/img/home/boult.png" />
    </div> -->
    <div class="foot">
      <div class="foot-item">
        <div class="item-left">
          <div class="left-total">
            <p class="total-num">{{ obj.capital.totalCapital }}</p>
            <p class="total-text">今日资金总收入 (元)</p>
          </div>
          <div class="left-flex">
            <div class="flex-item">
              <p class="total-num">{{ obj.capital.cash }}</p>
              <p class="total-text">现金收入</p>
            </div>
            <div class="flex-item">
              <p class="total-num">{{ obj.capital.mobile }}</p>
              <p class="total-text">移动收入</p>
            </div>
          </div>
        </div>
        <div class="item-right">
          <div class="right-total">
            <p class="total-num">{{ obj.revenue.totalRevenue }}</p>
            <p class="total-text">今日总营收 (元)</p>
          </div>
          <div class="right-flex">
            <div class="flex-item">
              <p class="total-num">{{ obj.revenue.networkFee }}</p>
              <p class="total-text">上网费</p>
            </div>
            <div class="flex-item">
              <p class="total-num">{{ obj.revenue.sale }}</p>
              <p class="total-text">商品收入</p>
            </div>
          </div>
        </div>
      </div>
      <div class="foot-top">
        <div class="top-left">
          <div class="left-total">
            <p class="total-num">{{ obj.memberNum.memberTotal }}</p>
            <p class="total-text">总会员数</p>
          </div>
          <div class="left-total left-top-total">
            <p class="total-top-num">{{ obj.memberNum.todayAdd }}</p>
            <p class="total-top-text">今日新增会员</p>
          </div>
        </div>
        <div class="top-right">
          <div class="right-total">
            <p class="total-num">{{ obj.onlineMember.onlineNum }}</p>
            <p class="total-text">总在线数</p>
          </div>
          <div class="right-flex">
            <div class="flex-item">
              <p class="total-num">{{ obj.onlineMember.deviceNum }}</p>
              <p class="total-text">总机器数</p>
            </div>
            <div class="flex-item">
              <p class="total-num">{{ obj.onlineMember.rate }}</p>
              <p class="total-text">上座率</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, TreeSelect } from "vant";
export default {
  name: "home",
  data() {
    return {
      obj: {
        capital: {}, // 今日资金
        revenue: {}, // 今日营收
        memberNum: {}, // 总会员数
        onlineMember: {} // 总在线数
      }
    };
  },
  mounted() {
    this.hadleFirstData();
  },
  methods: {
    hadleFirstData() {
      let _this = this;
      _this.$API.getBossPage().then(function(res) {
        if (res.code == 200) {
          _this.obj = res.data;
          // _this.init();
        } else {
          Toast(res.msg);
        }
      });
    },
    // 初始化
    init() {
      //初始化
      let myChart = this.$echarts.init(document.getElementById("pie"));
      // 回执图表
      myChart.setOption({
        title: {
          zlevel: 0,
          subtext: this.obj.shopNum || 0,
          subtextStyle: {
            color: "#000000",
            fontWeight: "",
            fontSize: 34
          },
          x: "center", //文字位置
          y: "20%" //文字位置
        },
        tooltip: {
          show: false
        },
        legend: { show: false },
        graphic: [
          //为环形图中间添加文字
          {
            type: "text",
            left: "center",
            top: "55%",
            style: {
              text: "总店数",
              textAlign: "center",
              fill: "#222222",
              fontSize: 16
            }
          }
        ],
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: "搜索引擎" },
              { value: 735, name: "直接访问" },
              { value: 580, name: "邮件营销" },
              { value: 484, name: "联盟广告" },
              { value: 300, name: "视频广告" }
            ]
          }
        ]
      });
      window.onresize = myChart.resize;
    },
    // 点击跳转分店列表
    handleListData() {
      this.$router.push({ name: "Store" });
    },
  }
};
</script>
<style lang="scss" scoped>
.home {
  padding: 26px 0;
  .home-title{
    display: flex;
    align-items: center;
    justify-content: center;
    >img{
      width: 46px;
      height: 42px;
      margin-left: 10px;
    }
  }
  h3 {
    font-size: 40px;
    // text-align: center;
  }
  .home-pie {
    width: 80%;
    margin: auto;
    margin-top: 50px;
    .pie-itme{
      width: 426px;
      height: 428px;
      margin: auto;
      text-align: center;
      background: url('~@/assets/img/home/pieBug.png');
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        font-size: 62px;
        color: #000;
        font-weight: 600;
      }
      .item-test{
        font-size: 26px;
        padding: 10px;
      }
      .item-data{


      }
    }
    #pie {
      height: 310px;
    }
  }
  .home-center {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222222;
    font-size: 26px;
    .log {
      width: 22px;
      height: 26px;
    }
    .boult {
      width: 10.8px;
      height: 21.7px;
    }
    div {
      padding: 0 10px;
    }
  }
  .foot {
    .foot-item {
      padding-top: 75px;
      display: flex;
      text-align: center;
      .item-left {
        flex: 5;
        padding-bottom: 45px;
        border-right: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        .left-total {
          .total-num {
            font-size: 34px;
            font-weight: bold;
            color: #F94454;
          }
          .total-text {
            font-size: 18px;
            color: #999999;
          }
        }
        .left-flex {
          display: flex;
          justify-content: center;
          margin-top: 45px;
          > div:nth-child(2) {
            margin-left: 33px;
          }
          > div {
            .total-num {
              font-weight: bold;
              font-size: 22px;
            }
            .total-text {
              font-size: 18px;
              color: #999999;
            }
          }
        }
      }
      .item-right {
        flex: 5;
        padding-bottom: 45px;
        border-bottom: 1px solid #f2f2f2;
        .right-total {
          .total-num {
            font-size: 34px;
            color: #F94454;
            font-weight: bold;
          }
          .total-text {
            font-size: 18px;
            color: #999999;
          }
        }
        .right-flex {
          display: flex;
          justify-content: center;
          margin-top: 45px;
          > div:nth-child(2) {
            margin-left: 33px;
          }
          > div {
            .total-num {
              font-weight: bold;
              font-size: 22px;
            }
            .total-text {
              font-size: 18px;
              color: #999999;
            }
          }
        }
      }
    }
    .foot-top {
      // padding-top: 45px;
      display: flex;
      text-align: center;
      .top-left {
        flex: 5;
        padding-top: 45px;
        padding-bottom: 45px;
        .left-total {
          .total-num {
            font-size: 34px;
            color: #F94454;
            font-weight: bold;
          }
          .total-text {
            font-size: 18px;
            color: #999999;
          }
          .total-top-num {
            font-size: 22px;
            font-weight: bold;
          }
          .total-top-text {
            font-size: 18px;
            color: #999999;
          }
        }
        .left-top-total {
          margin-top: 44px;
        }
      }
      .top-right {
        flex: 5;
        padding-top: 45px;
        padding-bottom: 45px;
        border-left: 1px solid #f2f2f2;
        .right-total {
          .total-num {
            font-size: 34px;
            color: #F94454;
            font-weight: bold;
          }
          .total-text {
            font-size: 18px;
            color: #999999;
          }
        }
        .right-flex {
          display: flex;
          justify-content: center;
          margin-top: 44px;
          > div:nth-child(2) {
            margin-left: 33px;
          }
          > div {
            .total-num {
              font-weight: bold;
              font-size: 22px;
            }
            .total-text {
              font-size: 18px;
              color: #999999;
            }
          }
        }
      }
    }
  }
}
</style>
